/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50: #e0eefc,
  100: #b3d4f7,
  200: #80b8f2,
  300: #4d9bed,
  400: #2685e9,
  500: #0070e5,
  600: #0068e2,
  700: #005dde,
  800: #0053da,
  900: #0041d3,
  A100: #fbfcff,
  A200: #c8d5ff,
  A400: #95aeff,
  A700: #7b9bff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-secondary: (
  50: #fbece7,
  100: #f5d1c4,
  200: #efb29d,
  300: #e99376,
  400: #e47b58,
  500: #df643b,
  600: #db5c35,
  700: #d7522d,
  800: #d24826,
  900: #ca3619,
  A100: #fffefd,
  A200: #ffd2ca,
  A400: #ffa697,
  A700: #ff907e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #e6e5ef,
  100: #c1bfd8,
  200: #9894be,
  300: #6e69a3,
  400: #4f4990,
  500: #30297c,
  600: #2b2474,
  700: #241f69,
  800: #1e195f,
  900: #130f4c,
  A100: #8b85ff,
  A200: #5b52ff,
  A400: #2b1fff,
  A700: #1305ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-warning: (
  50: #f7e0e0,
  100: #ebb3b3,
  200: #de8080,
  300: #d14d4d,
  400: #c72626,
  500: #bd0000,
  600: #b70000,
  700: #ae0000,
  800: #a60000,
  900: #980000,
  A100: #ffc3c3,
  A200: #ff9090,
  A400: #ff5d5d,
  A700: #ff4444,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$md-success: (
  50: #e4f3eb,
  100: #bde0cd,
  200: #91ccac,
  300: #64b88b,
  400: #43a872,
  500: #229959,
  600: #1e9151,
  700: #198648,
  800: #147c3e,
  900: #0c6b2e,
  A100: #9dffbc,
  A200: #6aff98,
  A400: #37ff75,
  A700: #1eff64,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
