.blurMe {
  filter: blur(5px);
}
.visibility-hidden {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -10000;
  overflow: hidden;
}
.ta-center {
  text-align: center;
}
.this-flex {
  display: flex;
  &.align-center {
    align-items: center;
  }
}
.mt-40 {
  margin-top: 40px;
}
button {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
}

.center-text {
  text-align: center;
}

$flexes: 1, 2, 4;
@each $flex in $flexes {
  .flex-#{$flex} {
    flex: #{$flex};
  }
}

.flex-end {
  text-align: end;
}

.f-bold {
  font-weight: bold;
}
