.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 3147483001;
  * {
    box-sizing: border-box;
  }

  > * > .ngx-toastr {
    position: relative;
    overflow: hidden;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 3px 1.5px #e1e9f3;
    color: #ffffff;
    &:hover {
      cursor: pointer;
    }
  }

  .ng-trigger {
    display: block;
    margin-bottom: 15px;
  }
}
.ngx-toastr {
  pointer-events: auto;
  margin-bottom: 15px;
}
