@import 'bootstrap-mixins';
@import 'scss/bootstrap-grid.min.css';
@import 'app/shared/alert/alert';

@import 'settings.material';
@import 'settings.colors';
@import 'shared';
@import 'pm-font';
@import 'mixins';
@import 'custom-material';

html,
body {
  height: 100%;
  background-color: var(--color-background-light);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

* {
  font-family: 'Poppins', sans-serif;
}

.ol-attribution {
  right: unset !important;
  left: 0.5em;
  flex-direction: row !important;
}
