$sorbus: #df643b;
$navy-blue: #0070e5;
$rock-blue: #8298b4;
$ghost-white: #f8f9fc;
$alpen-white: #f2f5fa;
$merlot-red: #bd0000;
$eucalyptus: #229959;
$white: #ffffff;
$dark-blue: #30297c;
$black: #000000;
$transparent-white: #fffe;
:root {
  --color-primary: #0070e5;
  --color-secondary: #df643b;
  --color-background-light: #f8f9fc;
  --color-background-dark: #8298b4;
  --color-success: #229959;
  --color-danger: #bd0000;
  --color-grc: #065a4a;
  --color-label: #8298b4;
}
