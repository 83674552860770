@import 'settings.colors';

mat-form-field.spaceless {
  & > .mat-mdc-form-field-wrapper {
    margin: 0;
    padding: 0;

    .mat-mdc-form-field-underline {
      display: none;
    }

    & .mat-mdc-form-field-flex {
      padding-top: 0;
      align-items: center;
    }
  }

  & > .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

mat-button-toggle-group.primary-color > .mat-button-toggle-checked {
  background-color: map_get($pasture-app-primary, 500);

  .mat-button-toggle-label-content {
    color: white;
  }
}

mat-form-field.inline-select {
  .mat-select-value {
    max-width: max-content;
  }

  .mat-mdc-form-field-infix {
    width: fit-content;
    border: none;
  }

  .mat-mdc-form-field-flex {
    padding: 0;
  }
}

mat-tab-group.headless {
  mat-tab-header {
    display: none;
  }
}

mat-dialog-container mat-dialog-content.mdc-dialog__content {
  overflow: visible;
}

:root * {
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-dialog-subhead-size: 22px;
  --mdc-list-list-item-label-text-size: 13px;
  --mdc-typography-button-letter-spacing: 0.5px;
  --mdc-typography-body1-letter-spacing: 0.5px;
  --mat-tab-header-label-text-letter-spacing: 0.5px;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.8);
  --mdc-typography-font-family: Poppins;
}

.mat-mdc-form-field-icon-suffix button {
  color: rgba(0, 0, 0, 0.87);
}

mat-tab-group.headless mat-tab-header {
  display: none;
}

mat-accordion.content-small-margins > mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding-left: 4px;
  padding-right: 4px;
}
